import { PureComponent } from 'react'

const RemoveIcon = class extends PureComponent {
  static displayName = 'RemoveIcon'

  render() {
    return (
      <svg
        className='btn__icon'
        width='460'
        height='512'
        viewBox='0 0 460 512'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Page-1' fill='none' fillRule='evenodd'>
          <g id='bin' fill='#D9534F' fillRule='nonzero'>
            <path
              d='M441.378906,70.621094 L335.449219,70.621094 L335.449219,35.929688 C335.449219,16.121094 319.328125,0 299.519531,0 L159.515625,0 C139.707031,0 123.585938,16.121094 123.585938,35.929688 L123.585938,70.621094 L17.65625,70.621094 C7.90625,70.621094 -2.84217094e-14,78.515625 -2.84217094e-14,88.277344 C-2.84217094e-14,98.035156 7.90625,105.929688 17.65625,105.929688 L36.0625,105.929688 L50.714844,442.898438 C52.394531,481.65625 84.101562,512 122.890625,512 L336.148438,512 C374.933594,512 406.636719,481.65625 408.320312,442.898438 L422.972656,105.929688 L441.378906,105.929688 C451.128906,105.929688 459.035156,98.035156 459.035156,88.277344 C459.035156,78.515625 451.128906,70.621094 441.378906,70.621094 Z M159.515625,35.308594 L299.519531,35.308594 L300.136719,70.621094 L159.105469,70.621094 L159.515625,35.308594 Z M373.042969,441.378906 C372.179688,461.171875 355.976562,476.691406 336.144531,476.691406 L122.886719,476.691406 C103.058594,476.691406 86.851562,461.171875 85.988281,441.378906 L71.402344,105.929688 L387.628906,105.929688 L373.042969,441.378906 Z'
              id='Shape'
            />
            <path
              d='M229.515625,406.070312 C219.765625,406.070312 211.863281,398.171875 211.863281,388.414062 L211.863281,176.550781 C211.863281,166.792969 219.765625,158.898438 229.515625,158.898438 C239.265625,158.898438 247.171875,166.792969 247.171875,176.550781 L247.171875,388.414062 C247.171875,398.171875 239.265625,406.070312 229.515625,406.070312 Z'
              id='Shape'
            />
            <path
              d='M300.136719,406.070312 C290.386719,406.070312 282.484375,398.171875 282.484375,388.414062 L282.484375,176.550781 C282.484375,166.792969 290.386719,158.898438 300.136719,158.898438 C309.886719,158.898438 317.792969,166.792969 317.792969,176.550781 L317.792969,388.414062 C317.792969,398.171875 309.886719,406.070312 300.136719,406.070312 Z'
              id='Shape'
            />
            <path
              d='M158.898438,406.070312 C149.144531,406.070312 141.242188,398.171875 141.242188,388.414062 L141.242188,176.550781 C141.242188,166.792969 149.144531,158.898438 158.898438,158.898438 C168.648438,158.898438 176.550781,166.792969 176.550781,176.550781 L176.550781,388.414062 C176.550781,398.171875 168.648438,406.070312 158.898438,406.070312 Z'
              id='Shape'
            />
          </g>
        </g>
      </svg>
    )
  }
}

export default RemoveIcon

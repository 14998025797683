import React from 'react'

function OrgSettingsIcon({ className, fill, height, width }) {
  return (
    <svg
      className={className}
      width={width || '100%'}
      height={height}
      viewBox='0 0 26 18'
    >
      <path
        d='M24.42 12.372a4.692 4.692 0 000-1.704l1.032-.596a.29.29 0 00.132-.34 6.003 6.003 0 00-1.328-2.296.292.292 0 00-.36-.056l-1.032.596a4.696 4.696 0 00-1.476-.852V5.932a.291.291 0 00-.228-.284c-.892-.2-1.8-.192-2.648 0a.291.291 0 00-.228.284v1.192c-.54.192-1.04.48-1.476.852l-1.032-.596a.293.293 0 00-.36.056 6.003 6.003 0 00-1.328 2.296.295.295 0 00.132.34l1.032.596a4.692 4.692 0 000 1.704l-1.032.596a.29.29 0 00-.132.34 6.033 6.033 0 001.328 2.296c.092.1.24.124.36.056l1.032-.596c.436.372.936.66 1.476.852v1.192c0 .136.096.256.228.284.892.2 1.8.192 2.648 0a.291.291 0 00.228-.284v-1.192c.54-.192 1.04-.48 1.476-.852l1.032.596a.293.293 0 00.36-.056 6.003 6.003 0 001.328-2.296.295.295 0 00-.132-.34l-1.032-.596zm-4.58 1.088a1.942 1.942 0 01-1.94-1.94c0-1.068.872-1.94 1.94-1.94s1.94.872 1.94 1.94-.868 1.94-1.94 1.94zm-16-5.78A2.562 2.562 0 006.4 5.12a2.562 2.562 0 00-2.56-2.56 2.562 2.562 0 00-2.56 2.56 2.562 2.562 0 002.56 2.56zm8.96 1.28c.076 0 .148-.02.224-.024a7.374 7.374 0 011.452-2.368c.296-.32.716-.504 1.156-.504.276 0 .548.072.784.212l.316.184c.032-.02.064-.036.096-.056.28-.584.448-1.232.448-1.92a4.478 4.478 0 00-4.48-4.48A4.47 4.47 0 008.32 4.48a4.478 4.478 0 004.48 4.48zm4.208 7.78c-.092-.048-.184-.104-.272-.156-.328.192-.612.392-1.1.392a1.59 1.59 0 01-1.156-.504 7.294 7.294 0 01-1.608-2.784c-.428-1.38.996-1.988 1.032-2.012a4.058 4.058 0 010-.312l-.316-.184a1.63 1.63 0 01-.392-.324c-.132.008-.26.024-.392.024-.984 0-1.904-.24-2.74-.64h-.332a4.61 4.61 0 00-4.612 4.608V16c0 1.06.86 1.92 1.92 1.92h10.216a1.54 1.54 0 01-.248-.812v-.368zM6.924 9.704A2.552 2.552 0 005.12 8.96H2.56A2.562 2.562 0 000 11.52v1.28c0 .708.572 1.28 1.28 1.28h2.636a5.866 5.866 0 013.008-4.376z'
        fill={fill || 'white'}
        fillRule='evenodd'
      />
    </svg>
  )
}

export default OrgSettingsIcon
